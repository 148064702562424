import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ContatoComponent } from './contato/contato.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';

import { WebFrameworkComponent } from './web-framework/web-framework.component';
import { MobileFrameworkComponent } from './mobile-framework/mobile-framework.component';
import { UserResolverService } from './resolves/user-resolver.service';
import { ServicosComponent } from './servicos/servicos.component';
import { EmBreveComponent } from './em-breve/em-breve.component';
import { SobreComponent } from './sobre/sobre.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { BotComponent } from './bot/bot.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, resolve: { user: UserResolverService } },
  { path: 'contato', component: ContatoComponent, resolve: { user: UserResolverService } },
  { path: 'servicos', component: ServicosComponent },
  { path: 'bot', component: BotComponent, canActivate: [AuthGuard] },
  /* { path: 'user-profile',     component: ProfileComponent },
  { path: 'landing',          component: LandingComponent },
  { path: 'web',              component: WebFrameworkComponent },
  { path: 'mobile',           component: MobileFrameworkComponent },*/
  { path: 'blog', redirectTo: 'embreve' },
  { path: 'treinamentos', redirectTo: 'embreve' },
  { path: 'embreve', component: EmBreveComponent },
  { path: 'sobre', component: SobreComponent, canActivate: [AuthGuard] },
  { path: 'register', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
