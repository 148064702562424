import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BotService {

  url = 'https://jdj-seven-bot.herokuapp.com'
  constructor(private http: HttpClient) { }

  getAllBots() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.url}/bot/bombgram/bots/?appId=bombgram`).subscribe((bots: any) => {
        console.log(bots);
        let allbots = []
        for (let bot of bots) {

          const { desactiveBots, activeBots } = bot

          for (let act of activeBots) {
            const { password, username, users } = act
            allbots.push({
              password,
              username,
              status: true,
              following: users.length
            })
          }
          for (let dea of desactiveBots) {
            const { password, username, users } = dea
            allbots.push({
              password,
              username,
              status: false,
              following: users.length
            })
          }
        }
        resolve(allbots)
      })
    })
  }

  requestFollowings(username, quantity) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.url}/bot/bombgram/bots?appId=bombgram&usuario=${username}`, {}).subscribe((bots: any) => {
        console.log(bots);
        resolve(bots)
      })
    })
  }

  requestNewBots(quantity) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.url}/bot/bombgram/bots/?appId=bombgram&quantity=${quantity}`, {}).subscribe((bots: any) => {
        console.log(bots);
        resolve(bots)
      })
    })
  }
}
