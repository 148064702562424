import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable, of, EMPTY, forkJoin } from 'rxjs';
import { mergeMap, take, first, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import * as nano from 'nanoid';

@Injectable({
  providedIn: 'root'
})
export class UserResolverService implements Resolve<any> {

  user: any = new Object();

  constructor(private routes: ActivatedRoute, private router: Router, private db: AngularFirestore, private http: Http) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> |
    Observable<never> {
    this.routes.queryParams.filter(params => params.user)
      .subscribe(params => {
        console.log(params);
        this.user = params.user;
        console.log(this.user);
      });

      return this.getIpCliente();
  }

  getIpCliente(): Observable<string> {
    return this.http.get('https://api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK') // ...using post request '
      .map((res: Response) => {
        let ipVar = res.text();
        const num = ipVar.indexOf(':');
        const num2 = ipVar.indexOf('\"});');
        ipVar = ipVar.slice(num + 2, num2);
        return ipVar;
      });
  }

}
