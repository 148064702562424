import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';

import { WebFrameworkComponent } from './web-framework/web-framework.component';
import { MobileFrameworkComponent } from './mobile-framework/mobile-framework.component';

import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { ContatoComponent } from './contato/contato.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { ServicosComponent } from './servicos/servicos.component';
import { EmBreveComponent } from './em-breve/em-breve.component';
import { SobreComponent } from './sobre/sobre.component';
import { AuthService } from './services/auth.service';
import { BotComponent } from './bot/bot.component';
import { BotService } from './services/bot.service';

@NgModule({
  declarations: [
    AppComponent,
    ContatoComponent,
    SignupComponent,
    LandingComponent,
    WebFrameworkComponent,
    MobileFrameworkComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    ServicosComponent,
    EmBreveComponent,
    SobreComponent,
    BotComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    NgbModule.forRoot(),
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  providers: [AuthService, BotService],
  bootstrap: [AppComponent]
})
export class AppModule { }
