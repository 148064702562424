import { Component, OnInit } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs';
import * as nano from 'nanoid';

import { ActivatedRoute } from '@angular/router';
import { Mesage } from '../models/mesage';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})

export class ContatoComponent implements OnInit {
  focus: any;
  focus1: any;

  alert = {
    show: false,
    type: 'success',
    strong: 'Sucesso!',
    message: 'Sua mensagem foi enviada e em breve entraremos em contato! obrigado!'
  };

  mesage: any;

  user: any = new Object();

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private http: Http) { }

  ngOnInit() {
    this.mesage = new Mesage(nano(6), '', '', '', '');
    this.route.data.subscribe((data: { user: any }) => {
      if (data.user) {
        this.user = data.user;
        this.route.queryParamMap.subscribe(params => console.log(params));
      } else {

      }
    });
  }
  async sendEmail() {
    try {
      const object = await(this.db.collection('users', ref => ref.where('user', '==', this.user)).get());
      await object.forEach(e => {
        this.user = e.docs.find(element => element.data().user === this.user);
      });
      if (!this.user.id) {
        const id = nano(6);
        this.user = { id: id, user: this.user, access: [new Date()] };
        await this.db.collection('users').doc(id).set(this.user);
        this.mesage.userId = this.user.id;
        await this.db.collection('mesages').doc(this.mesage.id).set(this.mesage);
      } else {
        this.user.data().access.push(new Date());
        this.mesage.userId = this.user.id;
        await this.db.collection('users').doc(this.user.data().id).update(this.user.data());
        const msg = {id: this.mesage.id, userId: this.mesage.userId, name: this.mesage.name,
          email: this.mesage.email, text: this.mesage.text};
          await this.db.collection('mesages').doc(this.mesage.id).set(msg);
        }
        this.mesage = new Mesage(nano(6), this.user.id, '', '', '');
      } catch (error) {
        this.alert.type = 'warn';
        this.alert.message = 'Ocorreu um imprevisto no envio da mensagem!';
        console.error(error);
      }
      this.alert.show = true;

  }

  close() {
    this.alert.show = false;
  }

}
