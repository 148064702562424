import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-framework',
  templateUrl: './web-framework.component.html',
  styleUrls: ['./web-framework.component.scss']
})

export class WebFrameworkComponent implements OnInit {
  focus: any;
  focus1: any;

  constructor() { }

  ngOnInit() {}

}
