import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-framework',
  templateUrl: './mobile-framework.component.html',
  styleUrls: ['./mobile-framework.component.scss']
})

export class MobileFrameworkComponent implements OnInit {
  focus: any;
  focus1: any;

  constructor() { }

  ngOnInit() {}

}
