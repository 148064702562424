import { Component, OnInit } from '@angular/core';
import { BotService } from '../services/bot.service';

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.css']
})
export class BotComponent implements OnInit {

  bots = null
  solicitation = {
    username: '',
    quantity: 0,
    loading: false
  }
  constructor(private botService: BotService) { }

  ngOnInit() {
    this.botService.getAllBots().then(e => {
      console.log(e)
      this.bots = e
    })
  }

  solicitarSeguidores() {
    this.solicitation.loading = true
    this.botService.requestFollowings(this.solicitation.username, this.solicitation.quantity).then(e => {
      console.log(e)
      //this.bots = e
      this.solicitation.loading = false

    }).catch((e) => {
      this.solicitation.loading = false
    })
  }

  solicitarBots() {
    this.solicitation.loading = true
    this.botService.requestNewBots(100).then(e => {
      console.log(e)
      //this.bots = e
      this.solicitation.loading = false

    }).catch((e) => {
      this.solicitation.loading = false
    })
  }

}
