export class Mesage {

    id: string;
    userId: string;
    name: string;
    email: string;
    text: string;

    constructor(id: string, userId: string, name: string, email: string, text: string) {
        this.id = id;
        this.userId = userId;
        this.name = name;
        this.email = email;
        this.text = text;
    }
}
