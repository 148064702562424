import { Component, OnInit } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs';

import * as nano from 'nanoid';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };

    user: any = new Object();

    focus;
    focus1;
    constructor(private db: AngularFirestore, private http: Http, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.data.subscribe((data: { user: any }) => {
            if (data.user) {
                this.createUser(data.user);
                this.route.queryParamMap.subscribe(params => console.log(params));
            } else {

            }
        });
    }

    async createUser(user) {
        try {
            const object = await (this.db.collection('users', ref => ref.where('user', '==', user)).get());
            await object.forEach(e => {
                this.user = e.docs.find(element => element.data().user === user);
            });
            if (!this.user) {
                const id = nano(6);
                this.user = { id: id, user: user, access: [new Date()] };
                await this.db.collection('users').doc(id).set(this.user);
            } else {
                this.user.data().access.push(new Date());
                await this.db.collection('users').doc(this.user.data().id).update(this.user.data());
            }
        } catch (error) {
            console.error(error);
        }
    }
}
